<template v-if="profileData">
  <div id="user-profile">
    <profile-header :employee-data="employeeData" :profile-data="profileData" />
    <!-- profile info  -->
    <section id="profile-info">
      <b-row>
        <b-col cols="3" order="2" order-lg="2">
          <ProfileAbout :profile-data="profileData" />
          <ProfileSkills :profile-data="profileData" />
        </b-col>
        <b-col lg="9" cols="10" order="2">
          <ProfilePolls :employee-data="employeeData" />
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import ProfileAbout from "./ProfileAbout.vue";
import ProfilePolls from "./ProfilePolls.vue";
import ProfileHeader from "./ProfileHeader.vue";
import ProfileSkills from "./ProfileSkills.vue";
import router from "@/router";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import store from "@/store";
/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,

    ProfileAbout,
    ProfilePolls,
    ProfileHeader,
    ProfileSkills,
  },
  data() {
    return {
      profileData: {},
      employeeData: {},
      userData: store.state.user.userData,

      employeeId: "",
    };
  },

  async created() {
    await this.getUserData();
    await this.getEmployeeData();
  },

  methods: {
    async getUserData() {
      await axios({
        method: "get",
        url: `${URL_API}profile/${router.currentRoute.params.id}`,
        headers: getHeader(this.userData),
      })
        .then((response) => {
          if (response.data === "") {
            this.profileData = {};
          } else {
            this.profileData = response.data;
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.profileData = {};
          }
        });

      await axios({
        method: "get",
        url: `${URL_API}user/${router.currentRoute.params.id}`,
        headers: getHeader(this.userDataObj),
      }).then((response) => {
        this.employeeId = response.data.employeeId;
      });
    },

    async getEmployeeData() {
      await axios({
        method: "get",
        url: `${URL_API}employee/${this.employeeId}`,
        headers: getHeader(this.userData),
      }).then((response) => {
        this.employeeData = response.data;
      });
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-profile.scss";
</style>
