<template v-if="ProfileData">
  <b-card
    class="card-profile"
    img-bottom
    v-bind:style="gradientColorBackground()"
  >
    <div class="position-relative">
      <div class="profile-img-container d-flex">
        <div class="profile-image-wrapper">
          <div
          >
            <b-avatar size="150" v-bind:src="'data:image/jpeg;base64,'+employeeData.avatar" />
          </div>
        </div>
        <div class="profile-title" style="margin-left: -120px; margin-top: 10px">
          <h2 class="text-white" style="margin-left: 140px; margin-top: 40px">
            {{ employeeData.fullName }}
          </h2>
          <p class="text-white text-left" style="margin-left: 150px">
            {{ userStatus(employeeData.projectRole) }}
          </p>
        </div>
      </div>
    </div>

    <br /><br />
  </b-card>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BImg,
  BNavbar,
  BNavbarToggle,
  BCollapse,
  BTabs,
  BNavItem,
  BButton,
  BAvatar,
  BAvatarGroup,
  BModal,
  VBModal,
  BForm,
  BFormGroup,
  BCol,
  BRow,
  BFormInput,
  BCardText,
  BFormTextarea,
  BTab,
  BFormTags,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BFormCheckbox,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import store from "@/store";

export default {
  components: {
    BCard,
    BTabs,
    BButton,
    BNavItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BImg,
    BAvatar,
    BAvatarGroup,
    BDropdown,
    BDropdownItem,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BModal,
    VBModal,
    BForm,
    BFormInput,
    BFormGroup,
    BCol,
    BRow,
    BCardText,
    BFormTextarea,
    BTabs,
    BTab,
    BFormCheckbox,
    BFormTags,
    BInputGroup,
  },

  data() {
    return {
      userData: store.state.user.userData,
      avatarText,

      color1: "#D5CEDC",
      color2: "#17F082",
      color3: "#17E082",
    };
  },

  methods: {
    gradientColorBackground() {
      if (this.profileData.backgroundProfile) {
        return `background: linear-gradient(45deg, ${this.profileData.backgroundProfile[0]}, ${this.profileData.backgroundProfile[1]}, ${this.profileData.backgroundProfile[2]})`;
      } else {
        return `background: linear-gradient(45deg, ${this.color1}, ${this.color2}, ${this.color3})`;
      }
    },

    getColLabel(colName) {
      return this.$i18n.t("message.profile." + colName);
    },

    userStatus(role) {
      if (role == "PROGRAMMER") {
        return this.getColLabel("programmer");
      }
      if (role == "TESTER") {
        return this.getColLabel("tester");
      }
      if (role == "MANAGER") {
        return this.getColLabel("manager");
      }
      if (role == "ANALYST") {
        return this.getColLabel("analyst");
      }
      if (role == "ARCHITECT") {
        return this.getColLabel("architect");
      }
      if (role == "TRAINEE") {
        return this.getColLabel("trainee");
      }
      if (role == "UNDEFINED") {
        return this.getColLabel("undefined");
      }
    },
  },
  directives: {
    Ripple,
  },
  props: {
    employeeData: {
      type: Object,
      required: true,
    },
    profileData: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style scoped>
#user-profile .profile-header {
  overflow: visible;
}

.avatar {
  margin-bottom: 1.25rem;
}

.dropdown.b-dropdown {
  margin-right: 10px;
}

.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  outline: none;
  animation: color-range 100s linear forwards paused;
  background: linear-gradient(90deg, currentColor 50%, rgba(0, 0, 0, 0.1) 50%);
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #313a46;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04aa6d;
  cursor: pointer;
}

@keyframes color-range {
  0% {
    color: #f57070;
  }
  50% {
    color: #f5f570;
  }
  100% {
    color: #70f570;
  }
}
</style>
