<template>
  <div>
    <b-container
      id="b-container"
      class="d-flex justify-content-between flex-column"
    >
      <b-row class="form2">
        <!-- card collapsible  -->
        <!-- mirror personal information  -->
        <b-col v-model="visible" md="12" class="mb-0 pl-0">
          <b-card-actions
            :title="$t('message.profile.personalInfo')"
            action-collapse
          >
            <b-form>
              <div class="info-person">
                <b-row class="mt-3 d-flex justify-content-center">
                  <b-col md="4">
                    <div style="text-align: center">
                      <feather-icon icon="UserIcon" />
                      <span class="font-weight-bold">
                        {{ $t("message.employees.fullname") }}</span
                      >
                      <b-form-group
                        style="text-align: center"
                        label-for="name"
                        class="mt-1"
                        >{{
                          employeeData.fullName || $t("message.profile.none")
                        }}
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col md="4">
                    <div style="text-align: center">
                      <feather-icon icon="MailIcon" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.personalEmail")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="personalEmail"
                        class="mt-1"
                        >{{
                          employeeData.internalEmail ||
                          $t("message.profile.none")
                        }}
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col md="4">
                    <div style="text-align: center">
                      <feather-icon icon="CalendarIcon" />
                      <span class="font-weight-bold">
                        {{ $t("message.employees.birthDate") }}
                      </span>
                      <b-form-group
                        style="text-align: center"
                        class="mt-1"
                        type="dateTime"
                      >
                        <a v-if="employeeData.dateBirth">
                          {{
                            $d(
                              new Date(employeeData.dateBirth),
                              "short",
                              locale
                            )
                          }}
                        </a>
                        <a v-else>
                          {{ $t("message.profile.none") }}
                        </a>
                      </b-form-group>
                    </div>
                  </b-col>
                </b-row>
                <!--  -->
                <b-row class="mt-3 d-flex justify-content-center">
                  <b-col md="4">
                    <div style="text-align: center">
                      <feather-icon icon="SmartphoneIcon" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.cellPhone")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="mobilePhone"
                        class="mt-1"
                        >{{
                          employeeData.mobilePhoneNumber ||
                          $t("message.profile.none")
                        }}
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col md="4">
                    <div style="text-align: center">
                      <feather-icon icon="HomeIcon" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.homeAddress")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="mobilePhone"
                        class="mt-1"
                      >
                        {{ employeeData.address || $t("message.profile.none") }}
                        -
                        {{
                          employeeData.district || $t("message.profile.none")
                        }}
                        -
                        {{ employeeData.city || $t("message.profile.none") }} -
                        {{ employeeData.uf || $t("message.profile.none") }}
                      </b-form-group>
                    </div>
                  </b-col>

                  <b-col md="4">
                    <div style="text-align: center">
                      <feather-icon icon="FlagIcon" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.state")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="birthPlaceState"
                        class="mt-1"
                        >{{
                          employeeData.birthPlaceState ||
                          $t("message.profile.none")
                        }}
                      </b-form-group>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-3 d-flex justify-content-center">
                  <b-col md="4">
                    <div style="text-align: center">
                      <feather-icon icon="UsersIcon" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.maritalStatus")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="maritalStatus"
                        class="mt-1"
                        >{{
                          employeeData.maritalStatus ||
                          $t("message.profile.none")
                        }}
                      </b-form-group>
                    </div>
                  </b-col>

                  <b-col md="4">
                    <div style="text-align: center">
                      <feather-icon icon="UserCheckIcon" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.gender")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="sex"
                        class="mt-1"
                        >{{ employeeData.sex || $t("message.profile.none") }}
                      </b-form-group>
                    </div>
                  </b-col>

                  <b-col md="4">
                    <div style="text-align: center">
                      <feather-icon icon="FlagIcon" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.naturalness")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="birthPlace"
                        class="mt-1"
                        >{{
                          employeeData.birthPlace || $t("message.profile.none")
                        }}
                      </b-form-group>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-3 d-flex justify-content-center"> </b-row>
              </div>

              <!-- fim das informaçõs pessoais -->
            </b-form>
          </b-card-actions>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style>
.ml-n6 {
  margin-left: -6em !important;
}

#b-container {
  max-width: 100%;
}

.info-person,
.info-profissionals {
  background-color: #f9f9f9;
  padding-top: 8px;
  padding-bottom: 20px;
  border-radius: 20px;
}

.form2 {
  margin-right: -1rem;
  margin-left: 0rem;
  padding-right: 0px;
}
#b-container {
  padding-right: 0px;
}

svg {
  margin-right: 5px;
}

.card .card-header .heading-elements {
  display: none;
}

.row {
  margin-left: -1rem;
}
</style>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BCol,
  BCardText,
  BFormGroup,
  BRow,
  BFormInput,
  BContainer,
  BCard,
  BForm,
} from "bootstrap-vue";

export default {
  components: {
    BCardActions,
    BCol,
    BCardText,
    BFormGroup,
    BRow,
    BFormInput,
    BContainer,
    BCard,
    BForm,
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.locale = newVal;
    },
  },

  props: {
    employeeData: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    if (this.$i18n.locale) {
      this.locale = this.$i18n.locale;
    }
  },

  data() {
    return {
      locale: "pt",
      visible: true,
      fields: ["ACTION", { key: "ICON", label: "ICON" }, "DETAILS"],
      items: [
        {
          ACTION: "Collapse",
          ICON: "ChevronDownIcon",
          DETAILS: "Collapse card content using collapse action.",
        },
      ],
    };
  },
};
</script>
