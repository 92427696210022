<template>
  <b-card>
    <!-- about -->
    <h6>{{ $t("message.profile.about") }}</h6>
    <p>{{ profileData.about || $t("message.profile.none") }}</p>
    <h6>{{ $t("message.profile.academic") }}</h6>
    <p>{{ profileData.academicEducation || $t("message.profile.none") }}</p>
    <h6>Linkedin</h6>
    <p>
      <a :href="profileData.linkedin">{{
        profileData.linkedin || $t("message.profile.none")
      }}</a>
    </p>
    <h6>Duolingo</h6>
    <a :href="profileData.duolingo">{{
      profileData.duolingo || $t("message.profile.none")
    }}</a>
  </b-card>
</template>

<script>
import { BCard, BCardText } from "bootstrap-vue";
import store from "@/store";

export default {
  components: {
    BCard,
    BCardText,
  },

  props: {
    profileData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      employeeData: store.state.user.employeeData.data,
      userData: store.state.user.userData,
    };
  },
};
</script>
